import React, { useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useQuery, useLazyQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import vibeImage from "../images/vibe-beta.png"
import InstaLogo from "../images/insta.png"

const GET_INVITED_BY = gql`
  query getInviteUser($userId: ID!) {
    getInviteUser(userId: $userId) {
      id
      username
      age
      profilePic
    }
  }
`

const GET_SPACE = gql`
  query getLimitedSpace($spaceId: ID!) {
    getLimitedSpace(spaceId: $spaceId) {
      space {
        id
        name
      }
      here
    }
  }
`

export default function SpaceInviteOnly(props) {
  let params = new URLSearchParams(props.location.search.substring(1))
  let userId = params.get("userid")
  let spaceId = params.get("spaceid")

  if (spaceId) {
    spaceId = spaceId.replace(
      /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g,
      ""
    )
  }

  if (userId) {
    userId = userId.replace(
      /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g,
      ""
    )
  }

  const { error: userError, data: userData } = useQuery(GET_INVITED_BY, {
    variables: { userId },
  })
  const [getSpace, { error: spaceError, data: spaceData }] = useLazyQuery(
    GET_SPACE,
    {
      variables: { spaceId },
    }
  )

  useEffect(() => {
    if (spaceId) {
      getSpace()
    }
  }, [])

  const redirect = () => {
    if (userId) {
      window.location.replace(
        `http://rgtk3.app.link/?$feature=profile&$custom_data=${userId}&$fallback_url=https://join-vibe-app.com/`
      )
    }
  }

  return (
    <PageWrapper>
      <Wrapper>
        <TitleBox>
          <Title>
            invited u to join{" "}
            <span style={{ color: " #fdde68" }}>
              #
              {spaceId && spaceData?.getLimitedSpace?.space?.name
                ? spaceData.getLimitedSpace.space.name
                : "privat-fest🔒"}
            </span>
          </Title>
          {spaceId && spaceData?.getLimitedSpace ? (
            <SubTitle>
              {userData?.getInviteUser?.username} and{" "}
              {spaceData?.getLimitedSpace?.here - 1} others are there partying👀
            </SubTitle>
          ) : (
            <SubTitle>
              {userData?.getInviteUser?.username} and others are there
              partying👀
            </SubTitle>
          )}
        </TitleBox>
        <ButtonBox>
          <button
            style={{
              background: "none",
              border: "none",
              outline: "none",
            }}
            onClick={() => redirect()}
          >
            <Button>
              <ButtonText>see on VIBE</ButtonText>
            </Button>
          </button>
        </ButtonBox>
        <InstaBox>
          <a
            style={{ textDecorationLine: "none" }}
            href="https://www.instagram.com/join_vibe/"
          >
            <InstaImage />
          </a>
        </InstaBox>
      </Wrapper>
    </PageWrapper>
  )
}

// Page Wrapper
const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #121212;
`

// Content wrapper
const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  align-self: center;
  background-color: #121212;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

const InviteBox = styled.div`
  position: absolute;
  top: 80px;
  align-self: center;
  width: 220px;
  height: 65px;
  background-color: #3a3a3a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
`
const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 50px;
  margin-left: 15px;
`

const Name = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  margin: 0;
  text-align: left;
`

const Grey = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #8a8a8a;
  font-size: 14px;
  margin: 0;
  line-height: 15px;
  text-align: left;
`

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.p`
  font-family: Gotham;
  font-size: 25px;
  letter-spacing: 2px;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  width: 240px;
`
const SubTitle = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 5px;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 28px;
  width: 240px;
  text-align: left;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
`
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  flex-direction: column;
`

const DescText = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 18px;
  width: 180px;
  margin-top: 15px;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
`

const Button = styled.div`
  width: 190px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: #fdde68;
`

const ButtonText = styled.p`
  text-align: center;
  vertical-align: middle;
  margin: 0;
  margin-bottom: 1px;
  text-decoration: none;
  color: #333;

  font-family: Arial, Helvetica Neue, Helvetica, sans-serif !important;
  font-weight: bold;
  font-size: 20px;
  line-height: 55px;
`
const InstaBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  background-color: #121212;
  width: 100%;
  margin-top: 75px;
`
const InstaImage = styled.img`
  content: url(${InstaLogo});
  flex: 1;
  width: 70px;
  height: 70px;
  object-fit: contain;
`
